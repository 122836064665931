<template>
  <div :class="$style.box">
    <BjBox ref="box" :scale="1" :lose="220" :deal-height="false">
      <div v-for="item in data" :key="item.id" :class="$style.item">
        <div :class="$style.img" class="cover" @click="preview(item)">
          <img :src="item.cover" />
          <div :class="$style.desc">
            {{ getTips(item) }}
          </div>
          <i v-if="item.material_type === 'audio'" :class="$style.music" class="ri-music-line" />
          <i v-if="item.material_type === 'video'" :class="$style.play" class="ri-play-circle-line" />
        </div>
        <div :class="$style.info">
          <div :class="$style.check">
            <a-checkbox :checked="selectedRowKeys.indexOf(item.id) !== -1" @change="onChange($event, item.id)" />
            <span :title="item.filename + item.ext" :class="$style.filename">{{ item.filename }}{{ item.ext }}</span>
          </div>
          <p :class="$style.time">
            <span>{{ locale(item.created_at) }}</span>
            <span :class="$style.state">{{ item.status | statusName }}</span>
          </p>
          <div :class="$style.btns">
            <i
              v-permission="'content.material.manage-edit'"
              :class="$style.btn"
              class="ri-edit-2-line"
              @click="onEdit(item)"
            />
            <i
              v-permission="'content.material.manage-download'"
              :class="$style.btn"
              class="ri-save-line"
              @click="onLoad(item)"
            />
            <i
              v-permission="'content.material.manage-download'"
              :class="$style.btn"
              class="ri-download-line"
              @click="onLoad(item)"
            />
            <a-popconfirm :title="item.refer ? quote : noquote" @confirm="onDelete(item)">
              <i
                v-permission="'content.material.manage-delete'"
                :class="$style.btnDelete"
                class="ri-delete-bin-7-line"
              />
            </a-popconfirm>
          </div>
        </div>
      </div>
    </BjBox>
    <div v-if="loading" :class="$style.loading">
      <a-spin />
    </div>
    <div v-if="!data.length" class="nodata">
      <img :src="oss + '/manage/media/state/no_data.png'" alt="" />
      <p>暂无数据</p>
    </div>
    <bj-modal title="编辑素材" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <BjInput
          v-model="edit.filename"
          label="图片名称"
          allow-clear
          v-bind="layout"
          label-align="left"
          rules="required"
          placeholder="请选择图片名称"
        />
        <BjValidationItem rules="required" label="选择分组" label-align="left" v-bind="layout">
          <bj-cascader
            v-model="edit.group_id"
            :options="typeData"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'children',
            }"
            :show-search="true"
            placeholder="请选择分组"
            :allow-clear="false"
          />
        </BjValidationItem>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import OSS from 'ali-oss'

import { materialService } from '@/service'
import { locale } from '@/utils'
import { status } from '@/utils/variable'

const service = new materialService()

export default {
  name: 'mBox',
  filters: {
    statusName(val) {
      return status[val]
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    typeData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      edit: {
        filename: null,
        group_id: [''],
      },
      visible: false,
      id: null,
      selectedRowKeys: [],
      quote:
        '该素材已被引用，若删除素材，引用素材的位置将无法正常显示和播放。确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。',
      noquote: '确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。',
    }
  },
  methods: {
    locale,
    getTips(item) {
      if (item.material_type === 'image') {
        return item.file_size_format + '；' + item.width + '*' + item.height
      } else {
        return item.file_size_format + '；' + item.duration
      }
    },
    preview(item) {
      let tmp = this.data.map(item => ({
        cover: item.path,
        type: item.material_type,
      }))
      this.BjPreview(tmp, item.path)
    },
    onEdit(item) {
      this.visible = true
      this.id = item.id
      this.edit.filename = item.filename
      this.edit.group_id = [item.group_id]
    },
    async onLoad(item) {
      if (item.status !== 1) {
        this.$message.error(`当前素材处于${status[item.status]}状态，请等待状态完成后再次尝试。`)
        return
      }
      const { data } = await service.getoss({ path: item.path })
      const client = new OSS({
        ...data,
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        stsToken: data.sts_token,
      })
      const filename = item.filename + item.ext
      const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`,
      }
      const url = client.signatureUrl(data.path, { response })
      window.open(url)
    },
    onChange(e, id) {
      if (e.target.checked) {
        this.selectedRowKeys.push(id)
      } else {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(id), 1)
      }
      this.$emit('selectChange', this.selectedRowKeys)
    },
    // eslint-disable-next-line vue/no-unused-properties
    onAllSelect(type) {
      this.selectedRowKeys = []
      if (!type) return
      this.data.map(item => {
        this.selectedRowKeys.push(item.id)
      })
    },
    async onDelete(item) {
      try {
        await service.materialDelete({ material_type: this.params.material_type, ids: [item.id] })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    async handleOk() {
      try {
        await service.materialEdit({
          ...this.params,
          ...this.edit,
          id: this.id,
          group_id: this.edit.group_id[this.edit.group_id.length - 1],
        })
        this.$message.success('编辑成功')
        this.visible = false
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.box {
  position: relative;
  padding-left: 20px;

  .item {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }

  .img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    text-align: center;
    background: #fafafa;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
      user-select: none;
    }

    .desc {
      position: absolute;
      right: 10px;
      bottom: 10px;
      height: 24px;
      padding: 0 6px;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    .music {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 24px;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 36px;
      transform: translate(-50%, -50%);
    }
  }

  .check {
    display: flex;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 18px;

    i {
      width: 38px;
      text-align: center;
      cursor: pointer;
    }
  }

  .info {
    padding: 10px 10px 16px;

    .filename {
      flex: 1;
      margin-left: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .time {
      margin: 10px 0 14px;
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .state {
    float: right;
    color: @primary-color;
  }

  .btn {
    color: #000;
  }

  .btn:hover {
    color: @primary-color;
  }

  .btn-delete {
    color: #000;
  }

  .btn-delete:hover {
    color: @error-color;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding-top: 200px;
  text-align: center;
  background: rgba(256, 256, 256, 0.4);
}
</style>
