<template>
  <div :class="$style.home">
    <div :class="$style.box">
      <a-tabs v-model="type" :class="$style.tab" :force-render="false">
        <a-tab-pane key="image" tab="图片">
          <common v-if="type === 'image'" ref="image" type="image" @refresh="getInfo" />
        </a-tab-pane>
        <a-tab-pane ref="audio" key="audio" tab="音频">
          <common v-if="type === 'audio'" type="audio" @refresh="getInfo" />
        </a-tab-pane>
        <a-tab-pane ref="video" key="video" tab="视频">
          <common v-if="type === 'video'" type="video" @refresh="getInfo" />
        </a-tab-pane>
        <div v-if="info.total_capacity" slot="tabBarExtraContent" :class="$style.btns">
          <div :class="[$style.badge, info.recycle_total === 0 ? $style.badgeno : null]" @click="onRecycle()">
            <recycle :count="info.recycle_total" @refresh="getInfo" />
          </div>
          <div :class="$style.line" />
          <div :class="$style.progress">
            <span>{{ info.used_capacity }}/{{ info.total_capacity }}</span>
            <a-progress
              :class="$style.progressItem"
              size="small"
              :percent="(info.used_capacity_byte / info.total_capacity_byte) * 100"
              :show-info="false"
            />
          </div>
          <a-popover placement="bottomRight">
            <template slot="content">
              <div :class="$style.volume">
                <p :class="$style.volumeTitle">容量统计</p>
                <div>
                  <div :class="$style.img" />
                  <span :class="$style.volumeStitle">图片</span>
                  <span :class="$style.tip" class="pull-right">共{{ info.image_used_capacity }}</span>
                </div>
                <div>
                  <div :class="$style.audio" />
                  <span :class="$style.volumeStitle">音频</span>
                  <span :class="$style.tip" class="pull-right">共{{ info.audio_used_capacity }}</span>
                </div>
                <div>
                  <div :class="$style.video" />
                  <span :class="$style.volumeStitle">视频</span>
                  <span :class="$style.tip" class="pull-right">共{{ info.video_used_capacity }}</span>
                </div>
              </div>
            </template>
            <bj-link type="primary">详情</bj-link>
          </a-popover>
          <bj-link type="primary">扩容</bj-link>
        </div>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import recycle from '@/components/materialRecycle'
import { materialService } from '@/service'

import common from './common.vue'

const service = new materialService()

export default {
  name: 'Home',
  components: {
    common,
    recycle,
  },
  data() {
    return {
      info: {},
      visible: {
        recycle: false,
      },
      type: 'image',
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const { data } = await service.info()
      this.info = data
    },
    onRecycle() {
      this.visible.recycle = true
    },
  },
}
</script>

<style lang="less" module>
.home {
  .box {
    padding: 20px;
    background: #fff;
  }

  :global {
    .ant-tabs-nav-wrap {
      height: 44px;
      color: #000;
    }

    .ant-tabs-content {
      height: calc(100% - 100px);
    }
  }

  .tab {
    min-height: calc(100vh - 190px);
  }

  .btns {
    display: flex;

    .progress {
      margin-right: 10px;
      color: #000;
      font-size: 12px;

      &-item {
        position: relative;
        top: -2px;
        left: 5px;
        display: inline-block;
        width: 160px;
      }
    }

    .line {
      height: 20px;
      margin: 12px 10px 0 0;
      border: 1px solid #eee;
    }

    .badge {
      width: 120px;
      height: 30px;
      color: #000;
      cursor: pointer;

      i {
        color: #000;
        font-size: 20px;
        vertical-align: 0;
      }
    }

    .badgeno {
      width: 80px;
    }
  }
}

.volume {
  width: 180px;
  font-size: 12px;

  & > div {
    margin-top: 10px;
  }

  &-title {
    color: #000;
    font-size: 14px;
  }

  &-stitle {
    color: #000;
  }

  .img {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: @primary-color;
  }

  .audio {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: #ffcb01;
  }

  .video {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: #ff2d55;
  }

  .tip {
    color: #5c5c5c;
  }
}
</style>
