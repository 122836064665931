<template>
  <div :class="$style.picture">
    <a-row>
      <a-col span="16">
        <bj-input v-model="search.keyword" class="w-200 mr-10" placeholder="请输入内容关键词" @pressEnter="onSearch">
          <i slot="prefix" class="ri-search-line" />
        </bj-input>
        <bj-select
          v-model="search.sort"
          class="w-200 mr-10"
          key-field="id"
          inner-search
          placeholder="请选择"
          value-field="id"
          label-field="label"
          show-all
          :options="sortOption"
        />
        <bj-button class="btn-default" @click="onSearch">搜索</bj-button>
      </a-col>
      <a-col span="8" :class="$style.btns">
        <div :class="$style.btnsMode">
          <displayMode v-model="active" @change="modeChange" />
        </div>
        <bj-button type="primary" @click="update()">
          <i :class="$style.icon" class="ri-upload-line" />
          上传{{ type | typeName }}
        </bj-button>
      </a-col>
    </a-row>
    <div :class="$style.box">
      <div :class="$style.left">
        <group :type="type" :active="search.group_id" @change="groupChange" @refresh="refreshGroup" />
      </div>
      <div id="right" :class="$style.right">
        <mTable
          v-if="!active"
          ref="table"
          :type="type"
          :params="params"
          :data="data"
          :type-data="typeData"
          :loading="loading"
          @selectChange="selectChange"
          @refresh="refresh"
        />
        <mbox
          v-else
          ref="box"
          :params="params"
          :data="data"
          :type="type"
          :loading="loading"
          :type-data="typeData"
          @selectChange="selectChange"
          @refresh="refresh"
        />
        <div class="mt-20">
          <a-row>
            <a-col span="8">
              <!-- <div :class="$style.footBtns">
                <a-checkbox v-if="!active" :checked="checked" @change="onAllSelect"> 全选 </a-checkbox>
                <span :class="$style.num">已选择{{ selectedRowKeys.length }}条</span>
                <BjLink type="primary" @click="onMove">移动</BjLink>
                <BjLink type="danger" @click="onDelete">删除</BjLink>
              </div> -->
            </a-col>
            <a-col span="16">
              <a-pagination
                :current="page"
                show-size-changer
                :show-total="total => `共 ${total} 条`"
                :page-size.sync="pageSize"
                :total="total"
                class="pull-right"
                @showSizeChange="onShowSizeChange"
                @change="onPageChange"
              />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div v-if="selectedRowKeys.length" :class="$style.option" :style="{ width: optionWidth }">
      <div :class="$style.optionBtn">
        <span>已选择{{ selectedRowKeys.length }}条</span>
        <span class="pull-right">
          <bj-button class="mr-10" type="primary" @click="onMove">移动分组</bj-button>
          <bj-button type="danger" @click="onDelete">删除素材</bj-button>
        </span>
      </div>
    </div>
    <uploadModal :type="type" :visible.sync="visible" :default-group="uploadGroup" />
    <bj-modal title="移动位置" :visible="moveVisible" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <BjValidationItem label="选择分组" label-align="left" v-bind="layout">
          <bj-cascader
            v-model="move.group_id"
            :options="typeData"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'children',
            }"
            :change-on-select="true"
            :show-search="true"
            placeholder="请选择分组"
            :allow-clear="false"
          />
        </BjValidationItem>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import displayMode from '@/components/displayMode'
import uploadModal from '@/components/uploadModal'
import { materialService } from '@/service'
import { sortOption } from '@/utils/variable'

import group from './group.vue'
import mbox from './mBox.vue'
import mTable from './mTable.vue'

const service = new materialService()

export default {
  name: 'common',
  filters: {
    typeName(val) {
      switch (val) {
        case 'image':
          return '图片'
        case 'video':
          return '视频'
        case 'audio':
          return '音频'
      }
    },
  },
  components: {
    displayMode,
    group,
    mTable,
    mbox,
    uploadModal,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: {
        keyword: null,
        sort: 'time_desc',
        group_id: 'all',
      },
      active: true,
      sortOption,
      visible: false,
      moveVisible: false,
      move: {
        group_id: [''],
      },
      selectedRowKeys: [],
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      typeData: [],
      loading: false,
      optionWidth: '800px',
    }
  },
  computed: {
    params() {
      return {
        material_type: this.type,
        group_id: this.search.group_id,
      }
    },
    refreshmaterial: {
      get() {
        return this.$store.state.refreshmaterial
      },
    },
    uploadGroup() {
      return this.search.group_id === 'all' ||
        this.search.group_id === 'last_time' ||
        this.search.group_id === 'default'
        ? 'default'
        : this.search.group_id
    },
  },
  watch: {
    refreshmaterial() {
      this.refresh()
    },
  },
  mounted() {
    this.optionWidth = document.getElementById('right').offsetWidth + 220 + 'px'
    window.addEventListener('resize', this.dealWidth)
  },
  created() {
    this.getGroup()
    this.refresh()
  },
  destroyed() {
    window.removeEventListener('resize', this.dealWidth)
  },
  methods: {
    dealWidth() {
      this.optionWidth = document.getElementById('right').offsetWidth + 220 + 'px'
    },
    groupChange(id) {
      this.search.group_id = id
      this.refresh()
    },
    refreshGroup() {
      this.getGroup()
    },
    onSearch() {
      this.page = 1
      this.refresh()
    },
    update() {
      this.visible = true
    },
    selectChange(val) {
      this.selectedRowKeys = val
    },
    modeChange(e) {
      !e ? (this.$refs.box.selectedRowKeys = []) : (this.$refs.table.selectedRowKeys = [])
      this.selectedRowKeys = []
    },
    onPageChange(page) {
      this.page = page
      this.refresh()
    },
    onShowSizeChange() {
      this.page = 1
      this.refresh()
    },
    onDelete() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择您要删除的素材')
        return
      }
      let flag = false
      this.data.map(item => {
        if (item.refer && this.selectedRowKeys.includes(item.id)) {
          flag = true
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: flag
          ? '素材中包含已被引用的素材，若删除素材，引用素材的位置将无法正常显示和播放。确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。'
          : '确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。',
        async onOk() {
          try {
            await service.materialDelete({
              material_type: _this.type,
              ids: _this.selectedRowKeys,
            })
            _this.$message.success('删除成功')
          } catch (e) {
          } finally {
            _this.page = 1
            _this.refresh()
          }
        },
      })
    },
    onMove() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择您要移动的素材')
        return
      }
      this.moveVisible = true
    },
    async refresh() {
      try {
        this.loading = true
        const payload = { material_type: this.type, ...this.search, page: this.page, page_size: this.pageSize }
        const { data } = await service.lists(payload)
        this.selectedRowKeys = []
        this.data = data.record
        this.total = data.total
        this.$emit('refresh')
        this.active ? (this.$refs.box.selectedRowKeys = []) : (this.$refs.table.selectedRowKeys = [])
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async getGroup() {
      const { data } = await service.getGroup({ material_type: this.type })
      data.map(item => {
        if (item.is_children_empty) {
          item.isLeaf = false
        }
      })
      this.typeData = [
        {
          name: '默认分组',
          id: '',
        },
      ].concat(data)
    },
    async handleOk() {
      try {
        await service.materialMove({
          material_type: this.type,
          group_id: this.move.group_id[this.move.group_id.length - 1],
          ids: this.selectedRowKeys,
        })
        this.$message.success('移动成功')
        this.moveVisible = false
        this.move.group_id = ['']
      } catch (e) {
      } finally {
        this.refresh()
        this.active ? (this.$refs.box.selectedRowKeys = []) : (this.$refs.table.selectedRowKeys = [])
      }
    },
    handleCancel() {
      this.move.group_id = ['']
      this.moveVisible = false
    },
  },
}
</script>

<style lang="less" module>
.picture {
  .btns {
    text-align: right;

    .icon {
      color: #fff;
    }

    &-mode {
      position: relative;
      top: 4px;
      display: inline-block;
      height: 20px;
      margin-right: 20px;
      line-height: 20px;
      border-right: 1px solid #eee;
    }
  }

  .box {
    display: flex;
    height: 100%;
  }

  .left {
    width: 220px;
  }

  .right {
    flex: 1;
  }

  .foot-btns {
    margin-top: 5px;
    padding-left: 20px;
    color: #000;
    text-align: left;

    .num {
      margin: 0 10px;
    }
  }
}

.option {
  position: fixed;
  bottom: 20px;
  height: 60px;
  padding: 0 0 0 240px;
  line-height: 60px;
  pointer-events: none;

  &-btn {
    padding: 0 20px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
    pointer-events: auto;
  }
}
</style>
