<template>
  <div :class="$style.table">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loading"
      row-key="id"
      :row-selection="{
        selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template #cover="item">
        <div :class="$style.box">
          <div :class="$style.cover" @click="preview(item)">
            <img :src="item.cover" />
            <i v-if="item.material_type === 'audio'" :class="$style.music" class="ri-music-line" />
            <i v-if="item.material_type === 'video'" :class="$style.play" class="ri-play-circle-line" />
          </div>
          <div :class="$style.name">
            <p :class="$style.filename" :title="item.filename + item.ext" class="ellipsis-width">
              {{ item.filename }}{{ item.ext }}
            </p>
            <p :class="$style.duration">
              <span v-if="type === 'image'">{{ item.width }} * {{ item.height }}</span>
              <span v-else>{{ item.duration }}</span>
            </p>
          </div>
        </div>
      </template>
      <template #refer="item">
        <BjLink type="primary" @click="onRefer(item)">{{ item.refer }}</BjLink>
      </template>
      <template #status="item">
        <BjTag :type="item.status ? 'primary' : 'danger'">{{ item.status | statusName }}</BjTag>
      </template>
      <template #action="item">
        <bj-link v-permission="'content.material.manage-edit'" type="primary" @click="onEdit(item)"> 编辑 </bj-link>
        <bj-link v-permission="'content.material.manage-download'" type="primary" @click="onLoad(item)"> 下载 </bj-link>
        <a-popconfirm :title="item.refer ? quote : noquote" placement="topLeft" @confirm="onDelete(item)">
          <bj-link v-permission="'content.material.manage-delete'" type="danger"> 删除 </bj-link>
        </a-popconfirm>
      </template>
    </a-table>
    <bj-modal title="编辑素材" :visible="visible" :class="$style.modal" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <BjInput
          v-model="edit.filename"
          label="图片名称"
          v-bind="layout"
          allow-clear
          label-align="left"
          rules="required"
          placeholder="请选择图片名称"
          class="mb-20"
        />
        <BjValidationItem rules="required" label="选择分组" label-align="left" v-bind="layout">
          <bj-cascader
            v-model="edit.group_id"
            :options="showTypeData"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'children',
            }"
            :change-on-select="true"
            :show-search="true"
            placeholder="请选择分组"
            :allow-clear="false"
          />
        </BjValidationItem>
      </BjForm>
    </bj-modal>
    <bj-modal
      title="引用"
      :visible="referVisible"
      :class="$style.modal"
      :width="800"
      :body-style="{ height: '500px', 'max-height': '500px', 'overflow-y': 'auto' }"
      @ok="referHandleOk"
      @cancel="referHandleCancel"
    >
      <a-table
        :columns="referColumns"
        :data-source="referData"
        :pagination="false"
        :loading="referLoading"
        row-key="id"
      />
    </bj-modal>
  </div>
</template>

<script>
import OSS from 'ali-oss'

import { materialService } from '@/service'
import { status } from '@/utils/variable'

const service = new materialService()

export default {
  name: 'mTable',
  filters: {
    statusName(val) {
      return status[val]
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    typeData: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      edit: {
        filename: null,
        group_id: [''],
      },
      visible: false,
      id: null,
      quote:
        '该素材已被引用，若删除素材，引用素材的位置将无法正常显示和播放。确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。',
      noquote: '确定要删除素材吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。',
      referVisible: false,
      referData: [],
      referLoading: false,
    }
  },
  computed: {
    showTypeData() {
      return this.typeData && this.typeData.length ? this.typeData.slice(1, this.typeData.length - 1) : []
    },
    columns() {
      return [
        {
          title: '图片',
          width: 300,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '大小', dataIndex: 'file_size_format' },
        // {
        //   title: '引用',
        //   scopedSlots: {
        //     customRender: 'refer',
        //   },
        // },
        { title: '上传时间', dataIndex: 'created_at' },
        {
          title: '状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
    referColumns() {
      return [
        { title: '引用模块', dataIndex: 'module_name' },
        { title: '引用对象', dataIndex: 'item_type_name' },
        { title: '对象位置', dataIndex: 'location_name' },
        { title: '引用时间', dataIndex: 'created_at' },
      ]
    },
  },
  methods: {
    preview(item) {
      let tmp = this.data.map(item => ({
        cover: item.path,
        type: item.material_type,
      }))
      this.BjPreview(tmp, item.path)
    },
    async onEdit(item) {
      try {
        const { data } = await service.getInfo({
          id: item.id,
          material_type: item.material_type,
        })
        this.visible = true
        this.id = item.id
        this.edit.filename = data.filename
        this.edit.group_id = data.group_ids
      } catch (e) {}
    },
    async onLoad(item) {
      if (item.status !== 1) {
        this.$message.error(`当前素材处于${status[item.status]}状态，请等待状态完成后再次尝试。`)
        return
      }
      const { data } = await service.getoss({ path: item.path })
      const client = new OSS({
        ...data,
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        stsToken: data.sts_token,
      })
      const filename = item.filename + item.ext
      const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`,
      }
      const url = client.signatureUrl(data.path, { response })
      window.open(url)
    },
    async onDelete(item) {
      try {
        await service.materialDelete({ material_type: this.params.material_type, ids: [item.id] })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.$emit('selectChange', this.selectedRowKeys)
    },
    async handleOk() {
      try {
        await service.materialEdit({
          ...this.params,
          ...this.edit,
          id: this.id,
          group_id: this.edit.group_id[this.edit.group_id.length - 1],
        })
        this.$message.success('编辑成功')
        this.visible = false
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    handleCancel() {
      this.visible = false
    },
    // eslint-disable-next-line vue/no-unused-properties
    onAllSelect(type) {
      this.selectedRowKeys = []
      if (!type) return
      this.data.map(item => {
        this.selectedRowKeys.push(item.id)
      })
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('selectChange', this.selectedRowKeys)
    },
    async onRefer(item) {
      if (!item.refer) return
      this.referVisible = true
      try {
        this.referLoading = true
        const { data } = await service.referList({
          type: item.material_type,
          id: item.id,
        })
        this.referData = data
        this.referLoading = false
      } catch (e) {}
    },
    referHandleOk() {
      this.referVisible = false
    },
    referHandleCancel() {
      this.referVisible = false
    },
  },
}
</script>

<style lang="less" module>
.table {
  margin-bottom: 20px;
  padding-left: 20px;

  .box {
    display: flex;
    align-items: center;
    width: 250px;
  }

  .cover {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #fafafa;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    .music {
      position: absolute;
      top: 4px;
      right: 4px;
      color: #fff;
      font-size: 18px;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 18px;
      transform: translate(-50%, -50%);
    }
  }

  .name {
    flex: 1;
    padding: 15px;

    .filename {
      color: #000;
      font-size: 14px;
    }

    .duration {
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .foot {
    margin-top: 100px;
    color: #000;
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
